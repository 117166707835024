<template>
	<div
		class="grey lighten-1 pa-3 d-flex align-start justify-space-between flex-column flex-md-row"
		v-if="nombreCompleto != ''"
	>
		<span><strong>{{nombreCompleto}}</strong></span>
		<span>{{categoriaMiembro}} {{ titularidad}}</span>
	</div>
</template>
<script>
	export default {
		name: 'subAppbarInfo',
		props:[
			'info'
		],
		computed: {
				nombreCompleto(){
					// const nombre = this.info.nombre.replace(/ .*/,'') || '?';
					// const apellido = this.info.apellido.replace(/ .*/,'') || '?';
					const nombre = this.info.nombre || '';
					const apellido = this.info.apellido || '';
					return `${nombre} ${apellido}`;
				},
				categoriaMiembro(){
					return this.info.categoria || '';
				},
				titularidad(){
					return this.info.miembro || '';
				}
			},

	}
</script>

<style lang="scss" scoped>

</style>