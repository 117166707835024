<template>
  <div>
    <v-app-bar
        dark
        color="primary"
        app
        flat
        clipped-left
    >
      <v-toolbar-title>SVDMQE - Miembros</v-toolbar-title>

      <v-spacer></v-spacer>


			<template v-if="!$vuetify.breakpoint.xs">
	      <v-btn text  x-small class="mr-2"  :to="{name:'Dashboard'}">
	        <v-icon class="mr-2">mdi-view-dashboard </v-icon> INICIO
	      </v-btn>
	      <v-btn text x-small  class="mr-2"  @click.stop="logout">
	        <v-icon class="mr-2">mdi-exit-to-app </v-icon> SALIR
	      </v-btn>
			</template>

			<v-menu
				offset-y
				rounded
				v-if="$vuetify.breakpoint.xs"
				transition="slide-y-transition"
      	bottom
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						outlined
						dark
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-menu</v-icon>
					</v-btn>
				</template>
				<v-list>

				<v-list-item :to="{name:'Dashboard'}">
					<v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
					<v-list-item-title>INICIO</v-list-item-title>
				</v-list-item>
				<!-- <v-list-item :to="{name:'Videos'}">
					<v-list-item-icon><v-icon>mdi-file-video-outline</v-icon></v-list-item-icon>
					<v-list-item-title>VIDEOS</v-list-item-title>
				</v-list-item>
				<v-list-item @click.stop="goCertificados">
					<v-list-item-icon><v-icon>mdi-card-account-details-outline</v-icon></v-list-item-icon>
					<v-list-item-title>CERTIFICADOS</v-list-item-title>
				</v-list-item> -->
				<v-list-item @click.stop="logout">
					<v-list-item-icon><v-icon>mdi-exit-to-app</v-icon></v-list-item-icon>
					<v-list-item-title>SALIR</v-list-item-title>
				</v-list-item>

			</v-list>
		</v-menu>

    </v-app-bar>
		<sub-toolbar :info="infoUser" />

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SubToolbar from './SubToolbar.vue';
export default {
	name: 'ToolbarUser',
	components: {SubToolbar },
  data: () => ({
    drawer: false,
    settings: [],
    items: [
      { text: 'CONTACT', to: 'ContactAutor' },
		],
		infoUser: {
			nombre: '',
			apellido: '',
			categoria: '',
			miembro: ''
		},

  }),
  mounted(){
		this.infoUser = JSON.parse(localStorage.getItem("info"));
  },
  methods: {
		goCertificados(){
			window.open('https://certificados.svderma.org','_blank')
		},
    logout(){
      this.$store.dispatch("userModule/loggedOut",{root: true});
    }
  },
  computed: {
  },

}
</script>

<style lang="scss" scoped>

</style>